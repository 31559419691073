exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog_category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-templates-compliance-js": () => import("./../../../src/templates/compliance.js" /* webpackChunkName: "component---src-templates-compliance-js" */),
  "component---src-templates-coverage-js": () => import("./../../../src/templates/coverage.js" /* webpackChunkName: "component---src-templates-coverage-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-platform-js": () => import("./../../../src/templates/platform.js" /* webpackChunkName: "component---src-templates-platform-js" */)
}

